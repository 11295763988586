<template>
  <div
    class="tw-flex tw-justify-between tw-items-center tw-h-16 tw-bg-[#f0f0f0] tw-px-6"
  >
    <a class="tw-flex tw-text-lg" href="#" @click.prevent="home">
      <img :src="rockstar" alt="Rockstar logo" class="tw-w-8" /> | Cfx
      <span class="tw-font-bold tw-ml-1">Bazaar</span>
    </a>
    <div
      class="tw-w-full tw-max-w-[12rem] md:tw-max-w-[20rem] lg:tw-max-w-[28rem] tw-relative"
    >
      <input
        placeholder="Search"
        class="tw-w-full tw-py-1 tw-px-4 tw-rounded-md tw-outline-none tw-border-2 focus:tw-border-primary"
        v-model="searchParam"
        @blur="search"
        @keyup.enter="search"
      />
      <font-awesome-icon
        icon="search"
        class="tw-absolute tw-right-2.5 tw-top-2.5 tw-opacity-50 tw-pointer-events-none"
      />
    </div>
    <div />
  </div>

  <div
    class="tw-flex tw-flex-wrap tw-gap-2 tw-w-fit tw-mx-auto tw-my-20 tw-px-4"
  >
    <button
      v-for="t in tags"
      :key="t"
      class="tw-text-white hover:tw-bg-primary tw-rounded-full tw-py-1 tw-px-4"
      :class="t === activeTag ? 'tw-bg-primary' : 'tw-bg-slate-700'"
      @click="filter(t)"
    >
      {{ t }}
    </button>
  </div>
</template>

<script>
import rockstar from './assets/rockstar.png'
import { ref } from 'vue'

const tags = [
  'Weapons',
  'Vehicles',
  'Props',
  'Peds',
  'Animations',
  'Clothes',
  'Maps',
  'Scripts',
  'Tools',
  'Misc',
]

export default {
  setup() {
    const searchParam = ref('')
    const activeTag = ref('')

    function filter(tag) {
      activeTag.value = activeTag.value === tag ? '' : tag
      sendMessage({ 'tags-in': activeTag.value })
    }

    function search() {
      sendMessage({ _q: searchParam.value })
    }

    function sendMessage(obj) {
      document.getElementById('modio_embed').contentWindow.postMessage(
        {
          modioHome: true,
          modioQuery: obj,
        },
        'https://embed.modhub.io'
      )
    }

    function home() {
      searchParam.value = ''
      activeTag.value = ''
      sendMessage({ _q: '', 'tags-in': '' })
    }

    return {
      searchParam,
      activeTag,
      rockstar,
      filter,
      search,
      home,
      tags,
    }
  },
}
</script>
