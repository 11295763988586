import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faSearch } from '@fortawesome/free-solid-svg-icons'

const solidIcons = [faSearch]

export const installedIcons = [...solidIcons.map((icon) => icon.iconName)]

library.add(...solidIcons)

export default FontAwesomeIcon
