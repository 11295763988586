import FontAwesomeIcon from '@plugins/fontawesome.js'
import { createApp } from 'vue'
import App from './App.vue'

// style imports
import 'highlight.js/styles/github-dark-dimmed.css'
import './sass/app.scss'
import './css/app.css'

const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon)

app.mount('#app')
